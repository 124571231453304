import { isDefined } from "@clipboard-health/util-ts";
import { post } from "@src/appV2/api/api";
import { API_TYPES } from "@src/appV2/api/constants";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

import { type PlacementDetail } from "./useGetPlacement";

export const createPlacementApplicationBodySchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.placementApplication),
    attributes: z
      .object({
        scheduleInterview: z
          .object({
            start: z.string(),
            end: z.string(),
          })
          .optional(),
      })
      .optional(),
    relationships: z.object({
      placementCandidate: z.object({
        data: z.object({
          type: z.literal(API_TYPES.placementCandidate),
          id: z.string(),
        }),
      }),
      placement: z.object({
        data: z.object({
          type: z.literal(API_TYPES.placement),
          id: z.string(),
        }),
      }),
    }),
  }),
});
export type CreatePlacementApplicationBody = z.infer<typeof createPlacementApplicationBodySchema>;

export const placementApplicationResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal(API_TYPES.placementApplication),
    attributes: z.object({
      status: z.string(),
      appliedDate: z.string(),
    }),
    relationships: z.object({
      placement: z.object({
        data: z.object({
          type: z.literal(API_TYPES.placement),
          id: z.string(),
        }),
      }),
      placementCandidate: z.object({
        data: z.object({
          type: z.literal(API_TYPES.placementCandidate),
          id: z.string(),
        }),
      }),
    }),
  }),
  included: z.array(
    z.discriminatedUnion("type", [
      z.object({
        type: z.literal(API_TYPES.placement),
        id: z.string(),
        attributes: z.object({
          name: z.string(),
        }),
        relationships: z.object({
          workplace: z.object({
            data: z.object({
              type: z.literal(API_TYPES.workplace),
              id: z.string(),
            }),
          }),
        }),
      }),
      z.object({
        type: z.literal(API_TYPES.workplace),
        id: z.string(),
        attributes: z.object({
          name: z.string(),
        }),
      }),
      z.object({
        type: z.literal(API_TYPES.placementCandidate),
        id: z.string(),
        attributes: z.object({
          status: z.string(),
          accessId: z.string(),
        }),
        relationships: z.object({
          worker: z.object({
            data: z.object({
              type: z.literal(API_TYPES.worker),
              id: z.string(),
            }),
          }),
        }),
      }),
      z.object({
        type: z.literal(API_TYPES.worker),
        id: z.string(),
        attributes: z.object({
          name: z.string(),
          email: z.string(),
          phone: z.string(),
        }),
      }),
      z.object({
        type: z.literal(API_TYPES.interview),
        id: z.string(),
        attributes: z.object({
          start: z.string(),
          end: z.string(),
          status: z.string(),
        }),
      }),
    ])
  ),
});

export type PlacementApplicationResponse = z.infer<typeof placementApplicationResponseSchema>;

interface CreatePlacementApplicationParams {
  placementCandidateId: string;
  placementDetails: PlacementDetail;
  scheduleInterview?: {
    start: string;
    end: string;
  };
}

export function useCreatePlacementApplication(
  options: UseMutationOptions<
    PlacementApplicationResponse,
    string,
    CreatePlacementApplicationParams
  > = {}
) {
  return useMutation<PlacementApplicationResponse, string, CreatePlacementApplicationParams>(
    ["createPlacementApplication"],
    async (params) => {
      const { placementCandidateId, placementDetails, scheduleInterview } = params;

      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/placement-applications`,
        data: {
          data: {
            type: API_TYPES.placementApplication,
            ...(isDefined(scheduleInterview) && {
              attributes: {
                scheduleInterview,
              },
            }),
            relationships: {
              placementCandidate: {
                data: {
                  type: API_TYPES.placementCandidate,
                  id: placementCandidateId,
                },
              },
              placement: {
                data: {
                  type: API_TYPES.placement,
                  id: placementDetails.id,
                },
              },
            },
          },
        },
        responseSchema: placementApplicationResponseSchema,
        requestSchema: createPlacementApplicationBodySchema,
      });

      logEvent(APP_V2_APP_EVENTS.PLACEMENT_APPLICATION_CREATED, {
        placementCandidateId,
        placementDetails,
        type: isDefined(scheduleInterview) ? "BOOKED_INTERVIEW" : "APPLIED",
        scheduleInterview,
      });

      return response.data;
    },
    {
      meta: {
        logErrorMessage: APP_V2_APP_EVENTS.PLACEMENT_APPLICATION_CREATE_FAILURE,
      },
      ...options,
    }
  );
}
